<template>
  <v-card outlined elevation="0" class="d-flex flex-column" min-height="80vh">
    <v-card-title class="text-h5 font-weight-medium">
      <v-btn class="px-n3" color="#122333" to="/assessments">
        <v-icon color="white">
          {{ mdiArrowLeft }}
        </v-icon>
      </v-btn>
      <div class="ml-5">Create New Assessment</div>
    </v-card-title>
    <v-stepper v-model="pos" elevation="0">
      <v-stepper-header>
        <v-stepper-step :complete="pos > 1" step="1">
          Basic Details
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="pos > 2" step="2">
          Select Skills
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="pos > 3" step="3"> Settings </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="4"> Final Preview </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <!-- basic details -->
        <v-stepper-content step="1">
          <v-form
            v-model="basicStep"
            class="basic pa-5"
            ref="basicDetails"
            lazy-validation
          >
            <v-row>
              <v-col cols="6">
                <label for="testname" class="font-weight-bold text-body-1">
                  Assessment Name
                </label>
                <v-text-field
                  v-model="testname"
                  id="testname"
                  outlined
                  placeholder="Name your assessment"
                  :rules="[(v) => !!v || 'Required']"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <label for="jobrole" class="font-weight-bold text-body-1">
                  Role
                </label>
                <v-text-field
                  v-model="role"
                  id="jobrole"
                  outlined
                  placeholder="Enter Job Role"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <label for="desc" class="font-weight-bold text-body-1">
              Description
            </label>
            <v-textarea
              id="desc"
              v-model="description"
              outlined
              rows="8"
              placeholder="Enter description about assessment and/or job role"
            >
            </v-textarea>
          </v-form>
        </v-stepper-content>
        <v-stepper-content step="2">
          <v-card class="pa-5" elevation="0">
            <v-card-text style="overflow-y: scroll">
              <!-- skills section -->
              <v-row>
                <!-- filter -->
              </v-row>
              <v-row>
                <v-chip
                  close
                  v-for="tag in skillTags"
                  :key="tag.id"
                  @click:close="rmSkillFromTagList(tag)"
                  class="mx-2"
                >
                  {{ tag.title }}
                </v-chip>
              </v-row>
              <v-row>
                <v-col
                  v-for="(skill, idx) in skills"
                  :key="skill?.id"
                  class="pa-2 ma-0"
                  cols="10"
                  sm="6"
                  md="4"
                >
                  <v-card
                    color="#c4c4c435"
                    elevation="0"
                    outlined
                    height="100%"
                  >
                    <v-card-title
                      class="
                        d-flex
                        flex-row
                        justify-space-between
                        text-subtitle-1
                      "
                    >
                      <v-row>
                        <v-col cols="10">
                          <span class="font-weight-bold d-inline-flex">
                            {{ skill.title }}
                          </span>
                        </v-col>
                        <v-col cols="2" class="pr-5">
                          <div
                            v-if="!isPremium(skill) || hiringPlan !== 'Free'"
                          >
                            <div class="align-right">
                              <v-btn
                                v-if="!skill?.added"
                                @click="addSkillToTest(skill)"
                                icon
                              >
                                <v-icon large color="primary">
                                  {{ mdiPlus }}
                                </v-icon>
                              </v-btn>

                              <v-btn
                                v-if="skill?.added"
                                @click="removeSkillFromTest(skill)"
                                icon
                              >
                                <v-icon large color="primary">
                                  {{ mdiMinus }}
                                </v-icon>
                              </v-btn>
                            </div>
                          </div>
                          <div v-else>
                            <v-btn @click="showPlanLimited" icon>
                              <v-icon large color="red">
                                {{ mdiLockOutline }}
                              </v-icon>
                            </v-btn>
                          </div>
                        </v-col>
                      </v-row>
                    </v-card-title>
                    <v-card-text>
                      {{ displaySummary(skill) }}
                    </v-card-text>
                    <v-card-actions class="justify-space-between">
                      <div class="d-flex">
                        <v-icon>
                          {{ mdiTimer }}
                        </v-icon>
                        <div>
                          {{ displayTime(skill?.metaData?.timeLimit) }}
                        </div>
                      </div>
                      <div>
                        <v-dialog
                          :key="skill.title"
                          v-model="skillDlg[idx]"
                          width="95%"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <div class="d-flex">
                              <v-btn
                                text
                                v-bind="attrs"
                                v-on="on"
                                class="text-none"
                                @click="viewSkill(skill)"
                              >
                                <v-icon>
                                  {{ mdiInformation }}
                                </v-icon>
                                Details
                              </v-btn>
                            </div>
                          </template>
                          <SkillModal
                            v-if="viewingSkill && viewingSkill?.id === skill.id"
                            :skill="viewingSkill"
                            v-on:closemodal="closeSkillModal(idx)"
                          />
                        </v-dialog>
                      </div>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-row>
            <v-col cols="12" md="6">
              <v-card class="pa-5" elevation="0">
                <v-card-title> Anti-Cheat Settings </v-card-title>
                <v-card-text>
                  <!-- @TODO: Change after MVP -->
                  <v-checkbox
                    disabled
                    v-model="antiCheat"
                    :value="1"
                    label="Disallow External Copy Paste"
                  >
                  </v-checkbox>
                  <v-checkbox
                    disabled
                    v-model="antiCheat"
                    :value="2"
                    label="Make Fullscreen"
                  >
                  </v-checkbox>
                  <v-checkbox
                    disabled
                    v-model="antiCheat"
                    :value="3"
                    label="Log tab change events"
                  >
                  </v-checkbox>
                  <!-- <v-checkbox
                disabled
                v-model="antiCheat"
                :value="4"
                label="Take Camera snapshots"
              >
              </v-checkbox> -->
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="6">
              <v-card elevation="0" class="pa-5">
                <v-card-title> Test Settings </v-card-title>
                <v-card-text>
                  <v-checkbox
                    v-model="testByUrlEnabled"
                    label="Enable Test Invite by URL"
                  >
                  </v-checkbox>
                  <div v-if="testByUrlEnabled">
                    <label for="url-expiry-picker" class="text-h6 black--text">
                      Set Expiry Date for Test URL
                    </label>
                    <div>
                      <v-date-picker
                        id="url-expiry-picker"
                        :allowed-dates="allowedDatesForLink"
                        v-model="testUrlExpiryEpoch"
                        elevation="1"
                      >
                      </v-date-picker>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-stepper-content>

        <v-stepper-content step="4">
          <v-card elevation="0">
            <v-card-title>
              <v-row class="pa-0 ma-0">
                <v-col cols="12" md="6">
                  <b>Test Name:</b> {{ testname }}
                </v-col>
                <v-col cols="12" md="6">
                  <b>Role:</b> {{ role ?? "Not set" }}
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text class="text-body-1 px-8 black--text">
              <v-row>
                <v-col cols="12" md="6">
                  <div class="text-h6 pt-5 font-weight-bold">Test Skills</div>
                  <ol>
                    <li v-for="sk in descTasks" :key="sk.id">
                      {{ sk.title }}
                    </li>
                  </ol>
                  <div class="text-h6 pt-5">
                    <b>Total Test Duration: </b> {{ displayTime(totalTimeInS) }}
                  </div>

                  <div class="text-h6 pt-5">
                    <b>Anti-cheat settings: </b>
                  </div>
                  <ul class="text-body-1">
                    <li v-if="extCopy">Disallow External Copy Paste</li>
                    <li v-if="tabLog">Log tab change events</li>
                    <li v-if="makeFullscreen">Make Fullscreen</li>
                    <li v-if="cameraSnap">Take Camera snapshots</li>
                  </ul>
                </v-col>
                <v-col cols="12" md="6" class="d-flex flex-column text-h6">
                  <div class="d-flex flex-row">
                    <b>Enable Test URL:</b>
                    <div class="pl-3">
                      {{ testByUrlEnabled ? "Yes" : "No" }}
                    </div>
                  </div>
                  <div class="d-flex flex-row">
                    <b v-if="testByUrlEnabled">Test URL Expiry:</b>
                    <div class="pl-3">
                      {{
                        testByUrlEnabled
                          ? new Date(testUrlExpiryEpoch).toDateString()
                          : ""
                      }}
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <v-spacer></v-spacer>
    <v-card-actions class="justify-space-between pb-16">
      <v-btn
        class="px-5 py-2 text-none"
        color="gray"
        outlined
        @click="back"
        :disabled="pos === 1"
      >
        Back
      </v-btn>
      <v-btn
        @click="next"
        color="success"
        class="text-none text-subtitle-1 px-5 py-2"
        :disabled="pos === 2 && !(skillIds.size > 0 && skillIds.size <= 5)"
        :loading="submitted"
      >
        {{ pos === 4 ? "Finish" : "Next" }}
      </v-btn>
    </v-card-actions>
    <div>
      <go-premium ref="unlock" imageUrl="images/GoPremium.png"> </go-premium>
    </div>
  </v-card>
</template>
<script>
import { mapActions, mapState, mapGetters } from "vuex";
import {
  mdiPlus,
  mdiMinus,
  mdiTimer,
  mdiInformation,
  mdiArrowLeft,
} from "@mdi/js";
import { mdiLockOutline } from "@mdi/js";
import SkillModal from "../../components/recruiter/SkillModal.vue";
import GoPremium from "../../components/assessment/GoPremium.vue";
import formattedTimeMixin from "@/mixins/formattedTimeMixin";
export default {
  components: { SkillModal, GoPremium },
  data() {
    return {
      pos: 1,
      testname: "",
      description: "",
      role: null,
      skills: [],
      skillIds: new Set(),
      skillTags: [],
      descTasks: [],
      // needs to be uniq
      basicStep: true,
      mdiPlus,
      mdiMinus,
      mdiTimer,
      mdiInformation,
      mdiArrowLeft,
      // no camera snaps now
      antiCheat: [1, 2, 3],
      submitted: false,
      skillDlg: [],
      viewingSkill: null,
      totalTimeInS: 0,
      mdiLockOutline,
      testByUrlEnabled: false,
      testUrlExpiryEpoch: "",
    };
  },
  props: {
    jobId: {
      default: String,
      required: false,
    },
  },
  mixins: [formattedTimeMixin],
  methods: {
    ...mapActions("recruiter", [
      "listSkills",
      "fetchSkill",
      "createAssessment",
      "updateAssessment",
    ]),
    ...mapActions("jobs", ["linkJobWithTest",]),
    rmSkillFromTagList(item) {
      this.skillTags = [...this.skillTags.filter((sk) => sk.id !== item.id)];
      console.log(item.id, this.skillIds);
      this.removeSkillFromTest(item);
    },
    closeSkillModal(index) {
      this.$set(this.skillDlg, index, false);
    },
    submit() {
      this.submitted = true;
      this.createAssessment({ title: this.testname }).then((res) => {
        this.updateAssessment({
          about: this.description,
          role: this.role,
          skills: this.skillIds,
          anticheat: this.antiCheat,
          enableLink: this.testByUrlEnabled,
          expiryEpoch: this.testUrlExpiryEpoch,
        })
          .then((r) => {
            // console.log(this.createdAssessment.toObject());
            if(this.jobId) {
              console.log("Res ..", res)
              this.linkJobWithTest({
                test_id: res.getContent().getId(),
                job_id: this.jobId,
              })
                .then(() => {
                  this.$store.dispatch("notifs/addNotif", {
                    text: "Successfully linked test with Job!",
                    type: "success",
                  });
                  this.linking = false;
                })
                .catch(() => {
                  this.linking = false;
                  this.$store.dispatch("notifs/addNotif", {
                    text: "Error occured while linking test with Job, please try again later!",
                    type: "error",
                  });
                });
            }
            this.$router.push(
              `/assessments/${this.createdAssessment.getUrl()}`
            );
          })
          .catch((e) => {
            console.error(e);
          });
      });
    },
    back() {
      if (this.pos !== 1) {
        this.pos = this.pos - 1;
      } else {
        this.pos = 1;
      }
    },
    next() {
      if (this.pos === 1) {
        if (this.$refs.basicDetails.validate()) {
          this.pos = 2;
        }
      } else if (this.pos === 2 || this.pos === 3) {
        this.pos += 1;
      } else if (this.pos === 4) {
        // console.log(`submit stuff`);
        this.submit();
      } else {
        // console.log("out of bounds");
      }
    },
    addSkillToTest(skill) {
      if (this.skillIds.size + 1 > 5) {
        this.$store.dispatch("notifs/addNotif", {
          text: "You can only add upto 5 skills!",
          type: "error",
        });
        return;
      }
      this.skillIds.add(skill.id);
      this.skillIds = new Set(this.skillIds.values());
      // this.skillIds.push(skill.id);
      this.$set(skill, "added", true);
      // console.log(this.skillIds, skill);
    },
    removeSkillFromTest(skill) {
      this.skillIds.delete(skill.id);
      this.skillIds = new Set(this.skillIds.values());
      // if (this.skillIds.findIndex(skill.id) > -1)
      //   this.skillIds.splice(this.skillIds.findIndex(skill.id), 1);
      this.$set(skill, "added", false);
      // console.log(this.skillIds, skill);
    },
    viewSkill(skill) {
      this.viewingSkill = skill;
      console.log(skill);
    },
    displaySummary(skill) {
      return skill.metaData?.skillMeta?.summary || "No summary";
    },
    isPremium(skill) {
      for (let tag of skill.metaData?.tagsList || [
        "skills/sample_assessment",
        "purpose/sample",
      ]) {
        if (tag === "purpose/sample" || tag === "skills/sample_assessment") {
          return false;
        }
      }
      return true;
    },
    async showPlanLimited() {
      if (
        await this.$refs.unlock.open(
          "To UNLOCK this feature, become a PREMIUM member today!"
        )
      ) {
        console.log("YEP");
      } else {
      }
    },
    allowedDatesForLink(selection) {
      // yyyy-mm-dd is the internal rep of date in v-date-picker
      // allowed date should be greater than today's date
      const selectedDate = new Date(selection);
      const today = new Date();
      return selectedDate > today;
    },
  },
  computed: {
    ...mapState("recruiter", ["assessment", "createdAssessment"]),
    ...mapState("customer", ["customer"]),
    ...mapGetters("customer", ["hiringPlanKeys", "prettyHiringPlans"]),
    extCopy() {
      return this.antiCheat.includes(1);
    },
    makeFullscreen() {
      return this.antiCheat.includes(2);
    },
    tabLog() {
      return this.antiCheat.includes(3);
    },
    cameraSnap() {
      return this.antiCheat.includes(4);
    },
    hiringPlan() {
      return this.prettyHiringPlans[
        this.hiringPlanKeys[this.customer?.getHiringPlan()]
      ];
    },
  },
  created() {
    this.listSkills({
      userId: this.$store.getters["user/user"].uid,
    }).then((r) => {
      const x = r?.toObject()?.contentsList;
      // this.skills = r?.toObject()?.contentsList;
      if (x.length > 0) {
        this.skills = x.filter((l) => l.contentType === 7);
        this.skillDlg = this.skills.map((_) => false);
      }
    });
    console.log("CUSTOMER", this.customer);
  },
  watch: {
    skillIds: function (n, o) {
      this.descTasks = [];
      this.skillTags = [];
      this.totalTimeInS = 0;
      this.skillIds.forEach((id) => {
        this.descTasks.push(this.skills.find((sk) => sk.id === id));
        this.totalTimeInS += this.skills.find(
          (sk) => sk.id === id
        ).metaData?.timeLimit;
        console.log(`totalTimeInS`, this.totalTimeInS);
      });
      this.skillIds.forEach((id) => {
        this.skillTags.push(this.skills.find((sk) => sk.id === id));
      });
    },
  },
};
</script>
<style scoped>
.v-stepper__header {
  box-shadow: none;
}
.skill-description {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  color: #333333;
}
</style>
