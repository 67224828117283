var render = function () {
  var _vm$role;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "d-flex flex-column",
    attrs: {
      "outlined": "",
      "elevation": "0",
      "min-height": "80vh"
    }
  }, [_c('v-card-title', {
    staticClass: "text-h5 font-weight-medium"
  }, [_c('v-btn', {
    staticClass: "px-n3",
    attrs: {
      "color": "#122333",
      "to": "/assessments"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "white"
    }
  }, [_vm._v(" " + _vm._s(_vm.mdiArrowLeft) + " ")])], 1), _c('div', {
    staticClass: "ml-5"
  }, [_vm._v("Create New Assessment")])], 1), _c('v-stepper', {
    attrs: {
      "elevation": "0"
    },
    model: {
      value: _vm.pos,
      callback: function callback($$v) {
        _vm.pos = $$v;
      },
      expression: "pos"
    }
  }, [_c('v-stepper-header', [_c('v-stepper-step', {
    attrs: {
      "complete": _vm.pos > 1,
      "step": "1"
    }
  }, [_vm._v(" Basic Details ")]), _c('v-divider'), _c('v-stepper-step', {
    attrs: {
      "complete": _vm.pos > 2,
      "step": "2"
    }
  }, [_vm._v(" Select Skills ")]), _c('v-divider'), _c('v-stepper-step', {
    attrs: {
      "complete": _vm.pos > 3,
      "step": "3"
    }
  }, [_vm._v(" Settings ")]), _c('v-divider'), _c('v-stepper-step', {
    attrs: {
      "step": "4"
    }
  }, [_vm._v(" Final Preview ")])], 1), _c('v-stepper-items', [_c('v-stepper-content', {
    attrs: {
      "step": "1"
    }
  }, [_c('v-form', {
    ref: "basicDetails",
    staticClass: "basic pa-5",
    attrs: {
      "lazy-validation": ""
    },
    model: {
      value: _vm.basicStep,
      callback: function callback($$v) {
        _vm.basicStep = $$v;
      },
      expression: "basicStep"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('label', {
    staticClass: "font-weight-bold text-body-1",
    attrs: {
      "for": "testname"
    }
  }, [_vm._v(" Assessment Name ")]), _c('v-text-field', {
    attrs: {
      "id": "testname",
      "outlined": "",
      "placeholder": "Name your assessment",
      "rules": [function (v) {
        return !!v || 'Required';
      }]
    },
    model: {
      value: _vm.testname,
      callback: function callback($$v) {
        _vm.testname = $$v;
      },
      expression: "testname"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('label', {
    staticClass: "font-weight-bold text-body-1",
    attrs: {
      "for": "jobrole"
    }
  }, [_vm._v(" Role ")]), _c('v-text-field', {
    attrs: {
      "id": "jobrole",
      "outlined": "",
      "placeholder": "Enter Job Role"
    },
    model: {
      value: _vm.role,
      callback: function callback($$v) {
        _vm.role = $$v;
      },
      expression: "role"
    }
  })], 1)], 1), _c('label', {
    staticClass: "font-weight-bold text-body-1",
    attrs: {
      "for": "desc"
    }
  }, [_vm._v(" Description ")]), _c('v-textarea', {
    attrs: {
      "id": "desc",
      "outlined": "",
      "rows": "8",
      "placeholder": "Enter description about assessment and/or job role"
    },
    model: {
      value: _vm.description,
      callback: function callback($$v) {
        _vm.description = $$v;
      },
      expression: "description"
    }
  })], 1)], 1), _c('v-stepper-content', {
    attrs: {
      "step": "2"
    }
  }, [_c('v-card', {
    staticClass: "pa-5",
    attrs: {
      "elevation": "0"
    }
  }, [_c('v-card-text', {
    staticStyle: {
      "overflow-y": "scroll"
    }
  }, [_c('v-row'), _c('v-row', _vm._l(_vm.skillTags, function (tag) {
    return _c('v-chip', {
      key: tag.id,
      staticClass: "mx-2",
      attrs: {
        "close": ""
      },
      on: {
        "click:close": function clickClose($event) {
          return _vm.rmSkillFromTagList(tag);
        }
      }
    }, [_vm._v(" " + _vm._s(tag.title) + " ")]);
  }), 1), _c('v-row', _vm._l(_vm.skills, function (skill, idx) {
    var _skill$metaData, _vm$viewingSkill;

    return _c('v-col', {
      key: skill === null || skill === void 0 ? void 0 : skill.id,
      staticClass: "pa-2 ma-0",
      attrs: {
        "cols": "10",
        "sm": "6",
        "md": "4"
      }
    }, [_c('v-card', {
      attrs: {
        "color": "#c4c4c435",
        "elevation": "0",
        "outlined": "",
        "height": "100%"
      }
    }, [_c('v-card-title', {
      staticClass: "\n                      d-flex\n                      flex-row\n                      justify-space-between\n                      text-subtitle-1\n                    "
    }, [_c('v-row', [_c('v-col', {
      attrs: {
        "cols": "10"
      }
    }, [_c('span', {
      staticClass: "font-weight-bold d-inline-flex"
    }, [_vm._v(" " + _vm._s(skill.title) + " ")])]), _c('v-col', {
      staticClass: "pr-5",
      attrs: {
        "cols": "2"
      }
    }, [!_vm.isPremium(skill) || _vm.hiringPlan !== 'Free' ? _c('div', [_c('div', {
      staticClass: "align-right"
    }, [!(skill !== null && skill !== void 0 && skill.added) ? _c('v-btn', {
      attrs: {
        "icon": ""
      },
      on: {
        "click": function click($event) {
          return _vm.addSkillToTest(skill);
        }
      }
    }, [_c('v-icon', {
      attrs: {
        "large": "",
        "color": "primary"
      }
    }, [_vm._v(" " + _vm._s(_vm.mdiPlus) + " ")])], 1) : _vm._e(), skill !== null && skill !== void 0 && skill.added ? _c('v-btn', {
      attrs: {
        "icon": ""
      },
      on: {
        "click": function click($event) {
          return _vm.removeSkillFromTest(skill);
        }
      }
    }, [_c('v-icon', {
      attrs: {
        "large": "",
        "color": "primary"
      }
    }, [_vm._v(" " + _vm._s(_vm.mdiMinus) + " ")])], 1) : _vm._e()], 1)]) : _c('div', [_c('v-btn', {
      attrs: {
        "icon": ""
      },
      on: {
        "click": _vm.showPlanLimited
      }
    }, [_c('v-icon', {
      attrs: {
        "large": "",
        "color": "red"
      }
    }, [_vm._v(" " + _vm._s(_vm.mdiLockOutline) + " ")])], 1)], 1)])], 1)], 1), _c('v-card-text', [_vm._v(" " + _vm._s(_vm.displaySummary(skill)) + " ")]), _c('v-card-actions', {
      staticClass: "justify-space-between"
    }, [_c('div', {
      staticClass: "d-flex"
    }, [_c('v-icon', [_vm._v(" " + _vm._s(_vm.mdiTimer) + " ")]), _c('div', [_vm._v(" " + _vm._s(_vm.displayTime(skill === null || skill === void 0 ? void 0 : (_skill$metaData = skill.metaData) === null || _skill$metaData === void 0 ? void 0 : _skill$metaData.timeLimit)) + " ")])], 1), _c('div', [_c('v-dialog', {
      key: skill.title,
      attrs: {
        "width": "95%"
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref) {
          var on = _ref.on,
              attrs = _ref.attrs;
          return [_c('div', {
            staticClass: "d-flex"
          }, [_c('v-btn', _vm._g(_vm._b({
            staticClass: "text-none",
            attrs: {
              "text": ""
            },
            on: {
              "click": function click($event) {
                return _vm.viewSkill(skill);
              }
            }
          }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v(" " + _vm._s(_vm.mdiInformation) + " ")]), _vm._v(" Details ")], 1)], 1)];
        }
      }], null, true),
      model: {
        value: _vm.skillDlg[idx],
        callback: function callback($$v) {
          _vm.$set(_vm.skillDlg, idx, $$v);
        },
        expression: "skillDlg[idx]"
      }
    }, [_vm.viewingSkill && ((_vm$viewingSkill = _vm.viewingSkill) === null || _vm$viewingSkill === void 0 ? void 0 : _vm$viewingSkill.id) === skill.id ? _c('SkillModal', {
      attrs: {
        "skill": _vm.viewingSkill
      },
      on: {
        "closemodal": function closemodal($event) {
          return _vm.closeSkillModal(idx);
        }
      }
    }) : _vm._e()], 1)], 1)])], 1)], 1);
  }), 1)], 1)], 1)], 1), _c('v-stepper-content', {
    attrs: {
      "step": "3"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card', {
    staticClass: "pa-5",
    attrs: {
      "elevation": "0"
    }
  }, [_c('v-card-title', [_vm._v(" Anti-Cheat Settings ")]), _c('v-card-text', [_c('v-checkbox', {
    attrs: {
      "disabled": "",
      "value": 1,
      "label": "Disallow External Copy Paste"
    },
    model: {
      value: _vm.antiCheat,
      callback: function callback($$v) {
        _vm.antiCheat = $$v;
      },
      expression: "antiCheat"
    }
  }), _c('v-checkbox', {
    attrs: {
      "disabled": "",
      "value": 2,
      "label": "Make Fullscreen"
    },
    model: {
      value: _vm.antiCheat,
      callback: function callback($$v) {
        _vm.antiCheat = $$v;
      },
      expression: "antiCheat"
    }
  }), _c('v-checkbox', {
    attrs: {
      "disabled": "",
      "value": 3,
      "label": "Log tab change events"
    },
    model: {
      value: _vm.antiCheat,
      callback: function callback($$v) {
        _vm.antiCheat = $$v;
      },
      expression: "antiCheat"
    }
  })], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card', {
    staticClass: "pa-5",
    attrs: {
      "elevation": "0"
    }
  }, [_c('v-card-title', [_vm._v(" Test Settings ")]), _c('v-card-text', [_c('v-checkbox', {
    attrs: {
      "label": "Enable Test Invite by URL"
    },
    model: {
      value: _vm.testByUrlEnabled,
      callback: function callback($$v) {
        _vm.testByUrlEnabled = $$v;
      },
      expression: "testByUrlEnabled"
    }
  }), _vm.testByUrlEnabled ? _c('div', [_c('label', {
    staticClass: "text-h6 black--text",
    attrs: {
      "for": "url-expiry-picker"
    }
  }, [_vm._v(" Set Expiry Date for Test URL ")]), _c('div', [_c('v-date-picker', {
    attrs: {
      "id": "url-expiry-picker",
      "allowed-dates": _vm.allowedDatesForLink,
      "elevation": "1"
    },
    model: {
      value: _vm.testUrlExpiryEpoch,
      callback: function callback($$v) {
        _vm.testUrlExpiryEpoch = $$v;
      },
      expression: "testUrlExpiryEpoch"
    }
  })], 1)]) : _vm._e()], 1)], 1)], 1)], 1)], 1), _c('v-stepper-content', {
    attrs: {
      "step": "4"
    }
  }, [_c('v-card', {
    attrs: {
      "elevation": "0"
    }
  }, [_c('v-card-title', [_c('v-row', {
    staticClass: "pa-0 ma-0"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b', [_vm._v("Test Name:")]), _vm._v(" " + _vm._s(_vm.testname) + " ")]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b', [_vm._v("Role:")]), _vm._v(" " + _vm._s((_vm$role = _vm.role) !== null && _vm$role !== void 0 ? _vm$role : "Not set") + " ")])], 1)], 1), _c('v-card-text', {
    staticClass: "text-body-1 px-8 black--text"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "text-h6 pt-5 font-weight-bold"
  }, [_vm._v("Test Skills")]), _c('ol', _vm._l(_vm.descTasks, function (sk) {
    return _c('li', {
      key: sk.id
    }, [_vm._v(" " + _vm._s(sk.title) + " ")]);
  }), 0), _c('div', {
    staticClass: "text-h6 pt-5"
  }, [_c('b', [_vm._v("Total Test Duration: ")]), _vm._v(" " + _vm._s(_vm.displayTime(_vm.totalTimeInS)) + " ")]), _c('div', {
    staticClass: "text-h6 pt-5"
  }, [_c('b', [_vm._v("Anti-cheat settings: ")])]), _c('ul', {
    staticClass: "text-body-1"
  }, [_vm.extCopy ? _c('li', [_vm._v("Disallow External Copy Paste")]) : _vm._e(), _vm.tabLog ? _c('li', [_vm._v("Log tab change events")]) : _vm._e(), _vm.makeFullscreen ? _c('li', [_vm._v("Make Fullscreen")]) : _vm._e(), _vm.cameraSnap ? _c('li', [_vm._v("Take Camera snapshots")]) : _vm._e()])]), _c('v-col', {
    staticClass: "d-flex flex-column text-h6",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-row"
  }, [_c('b', [_vm._v("Enable Test URL:")]), _c('div', {
    staticClass: "pl-3"
  }, [_vm._v(" " + _vm._s(_vm.testByUrlEnabled ? "Yes" : "No") + " ")])]), _c('div', {
    staticClass: "d-flex flex-row"
  }, [_vm.testByUrlEnabled ? _c('b', [_vm._v("Test URL Expiry:")]) : _vm._e(), _c('div', {
    staticClass: "pl-3"
  }, [_vm._v(" " + _vm._s(_vm.testByUrlEnabled ? new Date(_vm.testUrlExpiryEpoch).toDateString() : "") + " ")])])])], 1)], 1)], 1)], 1)], 1)], 1), _c('v-spacer'), _c('v-card-actions', {
    staticClass: "justify-space-between pb-16"
  }, [_c('v-btn', {
    staticClass: "px-5 py-2 text-none",
    attrs: {
      "color": "gray",
      "outlined": "",
      "disabled": _vm.pos === 1
    },
    on: {
      "click": _vm.back
    }
  }, [_vm._v(" Back ")]), _c('v-btn', {
    staticClass: "text-none text-subtitle-1 px-5 py-2",
    attrs: {
      "color": "success",
      "disabled": _vm.pos === 2 && !(_vm.skillIds.size > 0 && _vm.skillIds.size <= 5),
      "loading": _vm.submitted
    },
    on: {
      "click": _vm.next
    }
  }, [_vm._v(" " + _vm._s(_vm.pos === 4 ? "Finish" : "Next") + " ")])], 1), _c('div', [_c('go-premium', {
    ref: "unlock",
    attrs: {
      "imageUrl": "images/GoPremium.png"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }